<template>

  <v-card :loading="loaders.loading" flat>

    <v-card-text>
      <v-row>
        <v-col cols="12" lg="4" md="4">
          <image-upload-controller v-if="loaders.ready"
                                   :image="chapter.cover_src"
                                   :url="'upload.chapter'+(chapter.id ? '?id='+chapter.id : '')"
                                   :keyform="'chapter_form[cover]'"
                                   :label="'Charger la couverture'"
                                   @remove="()=> chapter.cover_image = ''"
                                   @uploaded="coverUploaded"
          ></image-upload-controller>
        </v-col>
        <v-col cols="12" lg="8" md="8">
          <h3>Detail</h3>
          <v-row>
            <v-col cols="12">
                <v-select
                  :loading="loaders.categories"
                  v-model="chapter.category_id"
                  outlined
                  dense
                  :items="categories"
                  label="Categorie"
                  item-value="id"
                  item-text="name"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name['fr'] }}
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      {{ data.item.name['fr'] }}
                    </v-list-item-content>
                  </template>
                </v-select>
            </v-col>
            <v-col v-if="!idcomic" cols="12">
              <template>
                <label for="">Sens de lecture </label>
                <v-radio-group v-model="chapter.readingdirection">
                  <v-radio label="De la droite vers la gauche" :value="0"></v-radio>
                  <v-radio label="De la gauche vers la droite" :value="1"></v-radio>
                  <v-radio label="Du haut vers le bas" :value="2"></v-radio>
                </v-radio-group>

              </template>

            </v-col>
            <v-col cols="12">
              <v-text-field dense outlined v-model="chapter.title" label="Titre"></v-text-field>
            </v-col>
            <v-col v-if="idcomic" cols="12">
              <v-text-field dense outlined v-model="chapter.number" label="Numero"></v-text-field>
            </v-col>
            <v-col v-if="!idcomic" cols="12">
              <v-textarea dense  outlined v-model="chapter.synopsis"
                          label="Synopsis"></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loaders.loading" @click="save(0)">
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="chapter.id" class="  pa-md-3 pa-lg-6">
      <v-alert
        color="blue"
        type="warning"
      >
        Dimension pour les pages la largeur doit etre comprise entre 800px et 1200px
        pour les doubles pages entre 1500 et 1800 pour un chargement optimal
      </v-alert>

    </v-card-text>
    <chapter-form-component
      v-if="chapter.id"
      :chapter="chapter"
      @changeCover="(idcover, nc) => chapter = nc"
    ></chapter-form-component>

    <v-card-actions class="mt-6 pb-16" v-if="chapter.id">

      <template  v-if="chapter.id" >
              <v-btn v-if="idcomic" text :to="`/scan/${chapter.comicbook_seo}/`+chapter.id">
                Previsualiser
              </v-btn>
              <v-btn v-else text :to="'/scan/one-shot/'+chapter.id">
                Previsualiser
              </v-btn>
      </template>

      <v-spacer></v-spacer>
      <v-btn color="secondary" :loading="loaders.loading" @click="save(0)">
        Enregistre comme brouillon
      </v-btn>
      <v-btn color="primary" class="ml-3" :loading="loaders.loading" @click="save(1)">
        Publier
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import {useRouter} from "@/utils";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ProfileItem from "@/components/ProfileItem.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
// import { useMeta } from 'vue-meta'

export default {
  components: {
    ChapterFormComponent,
    ImageUploadController,
    ProfileItem, FollowToggleComponent, Banner
  },
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter',to, from)
    // appelée avant que la route vers le composant soit confirmée.
    // cette fonction n'a pas accès à l'instance du composant avec `this`,
    // car le composant n'a pas encore été créé quand cette interception est appelée !
    next()
  },
  /*beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate', to, from)
    // appelée quand la route qui fait le rendu de ce composant change,
    // mais que ce composant est utilisé de nouveau dans la nouvelle route.
    // Par exemple, pour une route avec le paramètre dynamique `/foo/:id`, quand nous
    // naviguons entre `/foo/1` et `/foo/2`, la même instance du composant `Foo`
    // va être réutilisée, et ce hook va être appelé quand cela arrivera.
    // ce hook a accès à l'instance de ce composant via `this`.
  },*/
  /*beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave',to, from)
    // appelée quand la route qui fait le rendu de ce composant est sur le point
    // d'être laissée en faveur de la prochaine route.
    // elle a accès à l'instance de ce composant via `this`.

  },*/
  setup() {
    //useMeta({ title: 'Some Page' })
    const dialog = ref({
      newchapter: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: true,
      ready: false,
    })

    const {router} = useRouter()
    const route = router.currentRoute

    const user_id = ref(localStorage.getItem('user_id'))
    const user = ref(Drequest.getUser())

    const idcomic = ref(route.params.idcomic)
    const chapter = ref({id: parseInt(route.params.idchapter)})
    const e1 = ref(1)
    const cover = ref(1)
    const categories = ref([])

    const loadCategory = () => {

      Drequest.api(
        idcomic.value ? 'lazyloading.tree-item?dfilters=on&tree.name:eq=category_chapter'
          : 'lazyloading.tree-item?dfilters=on&tree.name:eq=category'
      )
        .get(response => {
          console.log(response)
          categories.value = response.listEntity
          loaders.value.categories = false
        })

    }

    if (chapter.value.id) {
      Drequest.api('detail.chapter?id=' + chapter.value.id).get((response) => {
        loaders.value.loading = false
        loaders.value.ready = true
        chapter.value = response.chapter
        idcomic.value = chapter.value.comicbook_id

        loadCategory();

      });
    }else {
      chapter.value.status = 1
      loaders.value.ready = true
      loaders.value.loading = false
      loadCategory();
    }

    const coverUploaded = (response) => {
      console.log(response)
      chapter.value.cover_image = response.chapter.cover_image
    }

    const scanpages = ref([]);
    const sortFiles = (scanpages) => {
      scanpages.value= scanpages
    }
    const save = (publish) => {

      loaders.value.loading = true
      let url;
      if (chapter.value.id) {
        url = 'update.chapter?id=' + chapter.value.id;
        if (scanpages.value.length){

        }
      }else
        url = 'chapter.create'

      Drequest.api(url)
        .data(
          {
            chapter: {
              'user.id': user.value.id,
              'title': chapter.value.title,
              status: publish,
              cover_image: chapter.value.cover_image,
              'tree_item\\category.id': chapter.value.category_id,
              'synopsis': chapter.value.synopsis, //$("#postcontent").html(),
              ...(idcomic.value ? {"comicbook.id": idcomic.value} : {})
            }
          })
        .raw((response) => {

          loaders.value.loading = false
          if (response.success) {
            if (url === 'chapter.create') {
              chapter.value.id = response.chapter.id
              chapter.value.status = 1
              e1.value = 2
            } else if (publish) {
              chapter.value = {}
              dialog.value.newchapter = false
              router.back()
            }
          }

        })

    }

    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,
      coverUploaded,
      save,

      categories,
      user,
      loaders,
      chapter,
      e1,
      cover,
      idcomic,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
